<template>
  <div class="row">
    <div class="col-12 col-lg-7 c g">
      <div class="card">
        <div class="card-header">
          <h3>
            <i class="fa fa-cogs"></i>
            إعدادات النظام
          </h3>
        </div>
        <div class="card-footer">
          <b-form-checkbox
            v-model="options.send_notification_to_all_when_start"
            @change="save()"
            switch
            inline
            class="custom-control-success"
            :value="true"
          >
            <h5>
              إرسال إشعار لجميع أولياء الأمور (المُستهدفين) عند بدء الرحلة
            </h5>
          </b-form-checkbox>
          <div
            class="form-group g"
            v-if="
              options.send_notification_to_all_when_start != 'false' &&
              options.send_notification_to_all_when_start != false
            "
          >
            <label for="">رسالة بدء الرحلة</label>
            <textarea
              @change="save()"
              class="form-control"
              name=""
              id=""
              rows="3"
              v-model="options.send_notification_to_all_when_start_text"
            ></textarea>
          </div>
        </div>
        <div class="card-footer">
          <b-form-checkbox
            v-model="options.send_notification_when_student_get_in"
            @change="save()"
            switch
            inline
            class="custom-control-success"
            :value="true"
          >
            <h5>ارسال اشعار لولي الامر عند ركوب ابنه في الباص</h5>
          </b-form-checkbox>
        </div>
        <div class="card-footer">
          <b-form-checkbox
            v-model="options.accept_all_app_devices"
            @change="save()"
            switch
            inline
            class="custom-control-success"
            :value="true"
          >
            <h5>قبول جميع الاجهزة عند تسجيل دخول اولياء الامور</h5>
          </b-form-checkbox>
          <br />
          <span
            class="text-danger"
            v-if="options.accept_all_app_devices != true"
            >سيتم مراجعة جميع الاجهزة يدوياً اذا اختلف الجهاز الذي تم الدخول به
            اول مرة.</span
          >
        </div>
        <div class="card-footer">
          <h5>اسماء الصفوف</h5>
          <div class="col-12-lg g" v-for="c in classes" :key="c">
            <div class="form-group">
              <h5 for="">{{ c }}</h5>
              <input
                type="text"
                class="form-control"
                v-model="changeclasses[c]"
              />
            </div>
          </div>
          <div class="col-12 text-center">
            <button class="btn btn-success" @click="changeClassesNow()">
              تغيير اسماء الصفوف <i class="fa fa-arrow-left"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-5 c g" v-if="user.all_rules">
      <div class="card card-body">
        <p>
          <i class="fa fa-trash"></i>
          حذف البيانات التراكمية
        </p>
        <button
          class="btn btn-block btn-outline-danger"
          @click="deleteNow('messages')"
        >
          <i class="fa fa-trash"></i>
          حذف الرسائل (التي بين المشرف واولياء الامور)
        </button>
        <br />
        <button
          class="btn btn-block btn-outline-danger"
          @click="deleteNow('logs')"
        >
          <i class="fa fa-trash"></i>
          حذف الـ Logs (السجلات)
        </button>
        <br />
        <button
          class="btn btn-block btn-outline-danger"
          @click="deleteNow('notis')"
        >
          <i class="fa fa-trash"></i>
          حذف اشعارات النظام
        </button>
        <br />
        <button
          class="btn btn-block btn-outline-danger"
          @click="deleteNow('archive')"
        >
          <i class="fa fa-trash"></i>
          حذف أرشيف الرحلات (سيؤدي الى حذف سجل رحلات الطلاب من حسابهم)
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BCardCode from "@core/components/b-card-code";
import { BFormCheckbox, BCardText } from "bootstrap-vue";
export default {
  components: {
    BCardCode,
    BFormCheckbox,
    BCardText,
  },
  data() {
    return {
      options: {},
      user: JSON.parse(localStorage.getItem("user")),
      classes: [],
      changeclasses: {},
    };
  },
  created() {
    checkPer(this, "settings", "allowed");
    var g = this;
    $.post(api + "/admin/options", {
      jwt: g.user.jwt,
    }).then(function (r) {
      g.options = JSON.parse(r);
    });
    $.post(api + "/admin/students/classes", {
      jwt: g.user.jwt,
    }).then(function (r) {
      g.classes = JSON.parse(r).response.classes;
      g.classes.forEach((element) => {
        g.changeclasses[element] = element;
      });
    });
  },
  methods: {
    changeClassesNow() {
      var g = this;
      $.post(api + "/admin/change-classes-names", {
        jwt: g.user.jwt,
        arr: g.changeclasses,
      })
        .then(function () {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "تم الحفظ",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch(function () {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ",
              icon: "TimesIcon",
              variant: "danger",
            },
          });
        });
    },
    save() {
      var g = this;
      $.post(api + "/admin/save-options", {
        jwt: g.user.jwt,
        options: g.options,
      })
        .then(function () {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "تم الحفظ",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch(function () {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ",
              icon: "TimesIcon",
              variant: "danger",
            },
          });
        });
    },
    deleteNow(type) {
      var g = this;
      if (prompt("اكتب كلمة المرور للتأكيد", "") == g.user.password) {
        $.post(api + "/admin/delete", {
          jwt: g.user.jwt,
          type: type,
        })
          .then(function () {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "تم الحذف بنجاح",
                icon: "CheckIcon",
                variant: "success",
              },
            });
          })
          .catch(function () {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ",
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          });
      } else {
        alert("كلمة المرور خطأ");
      }
    },
  },
};
</script>